:root {
  --main-theme-color: rgb(0, 106, 255);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

html {
  background-color: rgb(219, 219, 219);
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media print {
  #overview {
    box-shadow: none !important;
    position: absolute;
    top: 0;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}
