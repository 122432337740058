header {
  height: 100px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-theme-color);
}

header h1 {
  color: white;
  font-size: 3rem;
  font-weight: 700;
}
