:root {
  --input-gap: 10px;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.wrapper {
  width: 1650px;
}

h3 {
  margin-top: 30px;
  color: black;
}

h3:first-child {
  margin-top: 0;
}

label {
  color: grey;
}

input:focus,
textarea:focus {
  border-left: 3px solid var(--main-theme-color);
}

button {
  width: 100%;
  height: 35px;
  background-color: rgb(49, 49, 49);
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 10px;
  border: none;
}

.add-button:hover {
  background-color: rgb(87, 87, 87);
}

.delete-button:hover,
.reset-button:hover {
  background-color: rgb(174, 0, 0);
}

.edit-button {
  background: rgb(255, 157, 0);
}

.edit-button:hover {
  background: rgb(255, 176, 50);
}

.submit-button {
  background: green;
}

.submit-button:hover {
  background: rgb(0, 162, 0);
}

.final-buttons {
  margin-top: 20px;
  display: flex;
  gap: var(--input-gap);
}

label > p {
  margin-bottom: 0;
  font-weight: 600;
}

label div,
label input,
label textarea {
  height: 30px;
  width: 100%;
  font-size: 1rem;
  display: flex;
  align-items: center;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-bottom: 5px;
  padding: 5px 10px;
}

label textarea {
  resize: none;
  height: 150px;
}

#display {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}

#forms,
#overview {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#forms {
  padding: 20px;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  width: 800px;
}

.input-experience-dates,
.input-experience-location,
.input-education-location {
  display: flex;
  gap: var(--input-gap);
}

.input-experience-dates label,
.input-experience-location label,
.input-education-location label {
  width: 100%;
}

#overview {
  width: 800px;
  height: fit-content;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  overflow: scroll;
  height: 100vh;
}

.experience-info,
.education-info,
.personal-info {
  margin-top: 20px;
}

.overview-personal {
  height: fit-content;
}

.overview-card > * {
  font-size: 1rem;
}

.overview-name {
  font-size: 3rem;
  font-weight: 700;
}

.overview-card {
  font-size: 0.8rem;
}

.overview-contact {
  display: flex;
  gap: 20px;
}

.overview-lines {
  position: relative;
  top: 22px;
  border: 1px solid black;
}

.overview-desc-label,
.overview-exp,
.overview-edu {
  margin: 20px 0 10px 0;
  background-color: black;
  color: white;
  width: fit-content;
  padding: 5px;
}

.experience-item {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'position dates'
    'location location'
    'summary summary';
}

.experience-position {
  font-weight: 600;
  grid-area: position;
}

.experience-dates {
  grid-area: dates;
  text-align: right;
}

.experience-city {
  font-size: 0.8rem;
  font-style: italic;
}

.experience-location {
  margin-bottom: 10px;
}

.experience-summary {
  grid-area: summary;
}

.education-name {
  font-weight: 600;
}

.education-item {
  margin-bottom: 20px;
}

.education-location {
  font-weight: 400;
  font-size: 0.8rem;
  font-style: italic;
}

.print-menu {
  margin-top: 30px;
  display: flex;
  width: 800px;
  flex-direction: column;
  align-items: center;
  height: calc(100% - var(--footer-size));
}

.print-buttons {
  width: 100%;
  display: flex;
  gap: var(--input-gap);
  margin-bottom: 40px;
}

@media (max-width: 1680px) {
  html {
    font-size: 75%;
  }

  .wrapper {
    width: 1250px;
  }

  #forms,
  button,
  label input,
  label textarea {
    font-size: 1.5rem;
  }

  #forms,
  #overview,
  .print-menu {
    width: 600px !important;
  }

  label input,
  button {
    height: 40px;
  }
}

@media (max-width: 1280px) {
  .wrapper {
    width: 600px;
  }
  #display {
    flex-direction: column;
  }

  #overview {
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 50%;
  }

  .wrapper {
    width: 100%;
    margin-left: 20px;
  }

  #display,
  #forms,
  #overview,
  .print-menu {
    width: 100% !important;
  }

  #forms,
  button,
  label input,
  label textarea {
    font-size: 2rem;
  }

  .print-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin: 0;
  }

  .print-buttons button {
    margin: 0 !important;
  }
}
